import { get, set } from 'lodash-es'
import type { z } from 'zod'

export function formDataHash({ formData }: { formData: FormData }) {
  const result = {}

  for (const [key, value] of formData.entries()) {
    if (key in result) {
      // this handles the use case where we have the same key multiple times
      const previousValue = get(result, key)

      if (Array.isArray(previousValue)) {
        set(result, key, [...previousValue, isNumeric(value) ? Number(value) : value])
      } else {
        set(result, key, [previousValue, isNumeric(value) ? Number(value) : value])
      }
    } else {
      set(result, key, isNumeric(value) ? Number(value) : value)
    }
  }

  return result
}

const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number)

export function parse<T extends z.ZodRawShape>({
  formData,
  schema,
}: {
  formData: FormData
  schema: z.ZodObject<T>
}) {
  return schema.parse(formDataHash({ formData }))
}
